import { ref, watch } from "vue";
import { ElMessage } from "element-plus"
import axios from 'axios';
import { LearningCourse } from "@/types/learning_course";
import { LearningQuestionResult } from "@/types/learning_question_result";
import { LearningCourseQuestion } from "@/types/LearningCourseQuestion";
import { LearningRepository } from '@/repositories/LearningRepository'
import { LearningCourseRepository } from '@/repositories/LearningCourseRepository'
export const LearningCourseQuestionLogic = () => {

    const learningCourseQuestionErrors = ref("");
    const learningCourseQuestionLoading = ref(false)
    const learningCourse = ref<LearningCourse | null>(null)
    const learningCourseQuestion = ref<LearningCourseQuestion | null>(null)
    const learning_question_result = ref<LearningQuestionResult | null>(null);
    // 解いていない問題があるか
    const exists_unanswerd = ref(false);

    const getLearningCourse = async (projectId: number, learning_course_id: number) => {
        learningCourseQuestionLoading.value = true

        const repository: LearningCourseRepository = new LearningCourseRepository(projectId);
        try {
            learningCourse.value = await repository.get(learning_course_id);
            exists_unanswerd.value = true
            // await repository.existsUnanswered(learning_course_id);

            learningCourseQuestionLoading.value = false;

        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                learningCourseQuestionErrors.value = `取得に失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
            }
        }


    };
    const getLearningCourseQuestion = async (projectId: number, learning_course_id: number) => {
        learningCourseQuestionLoading.value = true;
        // 解答情報はnullに
        learning_question_result.value = null;

        const repository: LearningCourseRepository = new LearningCourseRepository(projectId);
        try {
            learningCourseQuestion.value = await repository.getQuestion(learning_course_id);

            learningCourseQuestionLoading.value = false;

        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                learningCourseQuestionErrors.value = `取得に失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
            }
        }
    };
    const sendAnswer = async (projectId: number, learning_course_id: number, learning_id: number, answer_time: number, answer_ids: number[]) => {
        learning_question_result.value = null;
        learningCourseQuestionLoading.value = true;
        exists_unanswerd.value = false;
        const repository: LearningRepository = new LearningRepository(projectId);
        const courseRepository: LearningCourseRepository = new LearningCourseRepository(projectId);
        try {
            learning_question_result.value = await repository.sendAnswer(learning_id, answer_time, answer_ids);
            exists_unanswerd.value = await courseRepository.existsUnanswered(learning_course_id);
            learningCourseQuestionLoading.value = false;
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                learningCourseQuestionErrors.value = `取得に失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
            }
        }

    };

    const LearningCourseStateReturn = () => {
        return {
            learningCourse,
            learningCourseQuestion,
            learning_question_result,
            exists_unanswerd,
            learningCourseQuestionLoading,
            learningCourseQuestionErrors,
            getLearningCourse,
            getLearningCourseQuestion,
            sendAnswer


        }
    }
    watch(learningCourseQuestionErrors, () => {
        if (learningCourseQuestionErrors.value == "") {
            return;
        }
        ElMessage({
            showClose: true,
            message: learningCourseQuestionErrors.value,
            type: 'error'
        });
        learningCourseQuestionErrors.value = "";
    })
    return {
        LearningCourseStateReturn,
        ...LearningCourseStateReturn()
    }
}


