import axios from "axios";
// import { DailyLearningAnswer } from '@/types/DailyLearningAnswer'
import { LearningCourse } from '@/types/learning_course'
import { LearningCourseQuestion } from '@/types/LearningCourseQuestion'
import { LearningQuestionResult } from '@/types/learning_question_result'
import { LearningCourseHistory } from '@/types/learning_course_history'

export class LearningRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  private url: string;
  constructor(private projectId: number) {
    this.url = process.env.VUE_APP_DOMAIN + 'v1/projects/' + projectId + '/learning_courses/'
  }

  async getLearningCourse(learning_course_id: number): Promise<LearningCourse> {
    return axios.get(this.url + learning_course_id, { params: { project_id: this.projectId } }).then((response) => {
      return response.data;
    });
  }
  async getLearningCourseQuestion(learning_course_id: number): Promise<LearningCourseQuestion> {

    return axios.get(this.url + learning_course_id + "/question", { params: { project_id: this.projectId } }).then((response) => {
      return response.data;
    });
  }
  // TODO 今後はanswer_idは複数あるので配列でうけとるようにする
  async sendAnswer(learning_id: number, answer_time: number, answer_ids: number[]): Promise<LearningQuestionResult> {
    return axios.post(LearningRepository.DOMAIN + "v1/projects/" + this.projectId + "/learning_answers/", { learning_answer: { learning_id: learning_id, answer_time: answer_time, answer_ids: answer_ids } }).then((response) => {
      return response.data;
    });
  }

  async getLearningCourseHistory(learning_course_id: number): Promise<LearningCourseHistory> {
    return axios.get(this.url + learning_course_id + "/history", { params: { project_id: this.projectId } }).then((response) => {
      return response.data;
    });
  }





}
